<template>
  <div class="q-pa-md">
    <Mural
      :notificationQuantity="listFavorites.length"
      :to="{ name: 'new-favorite' }"
      route="home"
      :query="{}"
      :breadcrumbs="breadcrumbs"
      
    >
      <DefaultBtn
        titleButton="Criar Favorito"
        icon="add"
        route="new-favorite"
        v-if="validateActionsPage('bookmarks-add')"
      />
    </Mural>

    <main class="q-ma-sm">
      <div class="content-table row flex">
        <div class="column col-12 q-pt-lg">
          <q-table
            class="table-content"
            :rows-per-page-options="[50]"
            dense
            bordered
            card-class="shadow-5"
            card-style="border-radius: 20px"
            color="default-pink"
            :rows="listFavorites"
            :columns="columnsTable"
            :filter="filter"
            row-key="name"
            virtual-scroll
          >
            <template v-slot:top-right>
              <q-input
                borderless
                dense
                debounce="300"
                v-model="filter"
                placeholder="Pesquisar"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="favoriteId" :props="props">
                  {{ props.row.id }}
                </q-td>
                <q-td key="Compartilhado" :props="props">
                  {{ props.row.shared_by }}
                </q-td>
                <q-td key="name" :props="props">
                  {{ props.row.name }}
                </q-td>
                <q-td key="url" :props="props">
                  <a target="_blank" :href="props.row.url">
                    {{ props.row.url }}
                    <q-icon
                      size="xs"
                      class="material-icons-outlined"
                      name="open_in_new"
                  /></a>
                </q-td>

                <q-td key="acao" :props="props">
                  <div>
                    <q-btn
                      dense
                      round
                      color="default-pink"
                      flat
                      @click="edit(props.row)"
                      icon="o_edit_note"
                      v-if="validateActionsPage('bookmarks-edit')"
                    />
                    <q-btn
                      dense
                      round
                      color="default-pink"
                      flat
                      @click="disabled(props.row)"
                      icon="o_delete_outline"
                      v-if="validateActionsPage('bookmarks-delete')"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>
    </main>
    <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div
        class="collumn justify-center q-my-md"
      >
        <div style="height: auto; width: 50px; margin: auto">
           <LoadingIcon  />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";


import { ref, onMounted } from "vue";
import FavoriteServices from "@/services/FavoriteServices";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
export default {
  name: "Favorites",
  components: {
    Mural,
    DefaultBtn,
    LoadingIcon
  },
  setup() {
    let _favoriteServices = new FavoriteServices();
    
    let $q = new useQuasar();
    let breadcrumbs = ref(["Links"]);
    let listFavorites = ref([]);
    let loadingPage = ref(false);
    let canUserAccessClass = new CanUserAccessClass();

    let _router = new useRouter();
    let columnsTable = ref([
      {
        name: "favoriteId",
        align: "left",
        label: "ID",
        field: "favoriteId",
        sortable: false,
      },
      {
        name: "Compartilhado",
        align: "left",
        label: "Compartilhado por",
        field: "Compartilhado",
        sortable: false,
      },
      {
        name: "name",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.name,
        sortable: true,
      },
      {
        name: "url",
        align: "left",
        label: "Url",
        field: "url",
        sortable: false,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acao",
        sortable: false,
      },
    ]);

    onMounted(() => {
      _listFavotires();
    });

    async function _listFavotires() {
      loadingPage.value = true;
      _favoriteServices.getAll().then((response) => {
        listFavorites.value = response.data;
        loadingPage.value = false;
      });
    }

    async function disabled(favorite) {
      $q.notify({
        message: `Deseja remover o link ${favorite.name}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await _removeFavorite(favorite.id);
            },
          },
        ],
      });
    }

    async function _removeFavorite(favoriteId) {
      loadingPage.value = true;
      await _favoriteServices.disabled(favoriteId).catch((error) => {
        alertErro(error.response.data.toString());
        loadingPage.value = false;
      });

      listFavorites.value = listFavorites.value.filter((favorite) => {
        return favorite.favoriteId != favoriteId;
      });
      loadingPage.value = false;
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function edit(favorite) {
      _router.push({
        name: "new-favorite",
        query: { favoriteId: favorite.id },
      });
    }

    return {
      breadcrumbs,
      listFavorites,
      filter: '',
      loadingPage,
      columnsTable,
      edit,
      disabled,
      validateActionsPage
    };
  },
};
</script>

<style scoped>
.content-table {
  height: 80vh;
}
</style>